const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#f3f3f3",
    padding: "30px 25px",
    borderRadius: "8px",
    position: "relative",
    width: "100%",
    maxWidth: "500px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    background: "transparent",
    border: "none",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "#333",
  },
};

const PopupModel = ({ children, onClose }) => (
  <button style={styles.closeButton} onClick={onClose}>
  <div style={styles.overlay}>
    <div style={styles.modal}>
      {children}
      
        
     
    </div>
  </div>
 </button>
);

export default PopupModel;
