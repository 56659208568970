import React, { useState, useEffect } from 'react'
import "../Css/HomePage.css"
import {createOrder, submitForm } from "../function/utils"
import logo from "../Images/logo.png"
import landingVideo from "../Images/landing-bg-video.mp4"
import correct from "../Images/correct.gif"
import QR from "../Images/payment_qr.jpg"
import PopupModel from "../Components/model";


export default function HomePage() {
    const [registrationScreen, setRegistrationScreen] = useState({
        show: false,
        mode: "payment"
    })
    //let url = "http://localhost:3000"
    let url = "https://filmgala-backend.onrender.com"
       //let url = "https://raja24-24.github.io/Filmgala-frontend/"
       const [data, setData] = useState({ name: "", studentIdentity:"", email: "", cname : "", ename : "", contact: "", tr : "", event: "celebrity-event", noofevent : "One", accom : "Yes"
    })
    const [images, setImages] = useState([]);
    const [showModel, setShowModel] = useState({
        show: false,
        imgIndex : 0
      });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        const importAll = (r) => {
          return r.keys().map(r);
        }
    
        const cineImages = importAll(require.context('../Images/Cine', false, /\.(png|jpe?g|svg)$/));
        const cineRulesImages = importAll(require.context('../Images/Cine Rules', false, /\.(png|jpe?g|svg)$/));
    
        const images = cineImages.map((image, index) => ({
          id: index + 1,
          img: image,
          onHover: cineRulesImages[index],
        }));
    
        setImages(images);
      }, []);


    const handleClosingOfRegistrationScreen = () => {
        setRegistrationScreen(false)
    }
    return (
        <div className='home-page'>
            <div className='navbar'>
                <a href='#' className="navbar-left">
                    <img src={logo} alt="" />
                </a>
                <div className="navbar-right">
                    <a href="#events" className='link-a'>Competitions</a>
                    <a href="#about" className='link-a'>Contact Us</a>
                    <a href="#registration" className='link-a btn-a'>Registration</a>
                </div>
            </div>
            <div className="landing-section">
                <video src={landingVideo} autoPlay muted loop></video>
                <h1>Feel The <span> Gaala </span>with your bestfriends !!<span> On April 24th & 25th</span></h1>
                <p>The students of Cinemates present FILM GALA’24 set to captivate audiences
on the 24th and 25th of April 2024. It offers an unparalleled chance for
aspiring filmmakers, not only within our university but also from
universities throughout India, to exhibit their talents on a grand stage.
Attendees will have the chance to immerse themselves in a rich tapestry of
cinematic experiences, including film screenings and engaging discussions
on the latest artistic developments in the industry.</p>
                
                <h4>ALL THE EVENT AT JUST <span>@300</span></h4>
                <a href="#events" className="btn-a link-a">Explore More</a>
            </div>
            <div className="event-section" id="events">
            {images.map(({ id, img, onHover }, index) => (
         <div key={id}>
          <div 
            id={'img1_' + index}
            className="service-card"
            onClick={() => {
              setShowModel({show : true, imgIndex : index})
            }}
            /* onMouseOver={() => handleImageChange(index)}
            onMouseOut={() => handleRouteClick(index)} */
          >
            <img src={img} alt="" />
          </div>
          {/* <div
            id={'img2_' + index}
            ref={(el) => (divRefs.current[index + images.length] = el)}
            className="service-card"
            style={{ display: 'none' }}
            onClick={() => handleRouteClick(index)}
          >
              <img src={onHover} alt="" />
          </div> */}
        </div>
      ))}
      {showModel.show &&
        <PopupModel onClose={() => {
          setShowModel({show : false, imgIndex : 0})
      }}>
        <img src={images[showModel.imgIndex].onHover} alt="" style={{width : '100%'}} />
      </PopupModel>}
      </div>
            <div className="about-section" id='about'>
                <div className="about-section-left">
                    <h2>Encourage Your Presence By Stepping Into The Best Events !!</h2>
                    <h3>Don't Miss The Moment</h3>
                </div>
                <div className="about-section-right">
                    <p>Have questions, suggestions, or feedback? We want to hear from you! Reach out to us via email or direct message on social media, and our team will be happy to assist you. Your input helps us create events that cater to your interests and preferences, so don't hesitate to get in touch.</p>
                 <br></br>                              

                 <div style={{ display: 'inline-block' }}>
    <h4 style={{ display: 'inline-block' }}>Follow us on &nbsp;&nbsp; <a href="https://www.instagram.com/cinemates.srmap?igsh=MTNpeHlrZnRkNGhsYw=="><img src={require("../Images/IMG_2111.PNG")} alt="Instagram" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }} /></a></h4>
</div>
<div style={{ display: 'inline-block', marginLeft: '20px' }}>
    <h4 style={{ display: 'inline-block' }}>Mail us on &nbsp;&nbsp;&nbsp;<a href="mailto:Club.cinemates@srmap.edu.in"><img src={require("../Images/IMG_2112.PNG")} alt="Mail" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }} /></a></h4>
</div>

<br></br>


                    <a href="https://wa.me/917997854466" className="btn-a link-a" target='_blank'>Connect With Us</a>
                </div>
            </div>
            <div className="registration-section" id='registration'>
                <h1>Register <span> Yourself</span></h1>
                <div className="registration-form">
                    <div className="input-box">
                        <p>Your Name</p>
                        <input type="text" name='name' value={data.name} className="input-field" required onChange={handleChange} />
                    </div>
                    <div className="input-box">
                        <p>University Name</p>
                        <input type="text" name='cname' value={data.cname} className="input-field" required onChange={handleChange} />
                    </div>
                    <div className="input-box">
                        <p>Student Id (Registraition No.)</p>
                        <input type="text" name='studentIdentity' value={data.studentIdentity} className="input-field" required onChange={handleChange} placeholder='AX45T....'/>
                    </div>
                    <div className="input-box">
                        <p>Your Email</p>
                        <input type="email" name='email' value={data.email} className="input-field" required onChange={handleChange} />
                    </div>
                    <div className="input-box">
                        <p>Your Phone Number</p>
                        <input type="number" name='contact' value={data.contact} className="input-field" required onChange={handleChange} />
                    </div>
                    <div className="input-box">
                        <p>Select No.of Competitions</p>
                        <select type="select" name="noofevent" value={data.noofevent} className='input-field' required onChange={handleChange} defaultValue={'One'} >
                            <option value="One">One</option>
                            <option value="Two">Two</option>
                            <option value="Three">Three</option>
                            <option value="Four">Four</option>
                            <option value="Five">Five</option>
                            <option value="Six">Six</option>
                            <option value="Seven">Seven</option>
                        </select>
                    </div>
                    <div className="input-box">
                        <p>Enter Your Competition Names (Ex:- Cinerythm,PlotSaga,......)</p>
                        <input type="text" name='ename' value={data.ename} className="input-field" required onChange={handleChange} />
                    </div>
                    <div className="input-box">
                        <p>Accommodation</p>
                        <select type="select" name="accom" className='input-field' required onChange={handleChange} value={data.accom} defaultValue={'Yes'}>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            
                        </select>
                    </div>
  

                    {/* <input type="submit" value="Complete Registration" className='btn-a' /> */}
                    <button className="btn-a" onClick={()=>{ 
                        let emptyFields = []; 
 
                        if(data.name === "") {
                            emptyFields.push("Name"); 
                        }                        
                        if(data.cname === "") { 
                            emptyFields.push("College Name"); 
                        } 
                        if(data.studentIdentity === "") { 
                            emptyFields.push("Student Identity"); 
                        } 
                        if(data.email === "") { 
                            emptyFields.push("E-Mail"); 
                        } 
                        if(data.contact === "") { 
                            emptyFields.push("contact"); 
                        } 
                        if(data.event === "") { 
                            emptyFields.push("Event"); 
                        } 
                         
                        if(emptyFields.length > 0) { 
                            alert("Please fill the following details: " + emptyFields.join(", ")); 
                            return; 
                        } 
                         
                        setRegistrationScreen((prev) => ({ 
                        ...prev, 
                        show: true, 
                        mode : "payment" 
                    }))}} > 
                        Complete Registration 
                    </button>
                </div>
            </div>
            <footer className="footer">
                <h4>This Website is Designed & Developed By <span>JSN Uday Kumar and RJ</span></h4>
            </footer>


            {registrationScreen.show && <div className="registration-number-screen">
                {
                    registrationScreen.mode === "payment" ? 
                    <div className="registration-number-box">
                        <img src={QR} style={{width: "400px", height: "250px"}} alt='QR' />
                        <p style={{ 
    backgroundImage: 'linear-gradient(to right, violet, indigo, blue, green, yellow, orange, red)', 
    WebkitBackgroundClip: 'text', 
    color: 'transparent', 
    display: 'inline-block',
    fontSize: '24px' // Adjust the font size as needed
}}>Pay Rs300 for Event</p>
<h5>If you have opted for Accommodation...Then you need to  pay Rs750</h5>

                        <p>Scan And Pay Now</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
    <p style={{ marginRight: '10px' }}>Enter Your Transaction Id :</p>
    <input type="text" name="tr" onChange={handleChange} value={data.tr} />
</div>

                        <input type="button" value="Submit Now" className="btn-a" onClick={async () => {
                            try {
                                if (data.tr === "") {
                                    alert("Please Enter Transaction Id");
                                    return;
                                }
                                let registrationNumber = await submitForm(data, url);
                                if(registrationNumber.success === true) {
                                    setRegistrationScreen((prev) => ({
                                        ...prev,
                                        show: true,
                                        registrationNumber: registrationNumber.registrationNumber,
                                        mode : "success"
                                    }));

                                } else {
                                    alert(registrationNumber.message);
                                    return ;
                                }
                                
                                
                            } catch (err) {
                                console.log(err);
                                return false;                                
                            }
                            }} />
                    </div> :
                    <div className="registration-number-box">
                    <img src={correct} alt="" />
                    <h3>Registration Successfull</h3>
                    <p>Here is your Registration Number , please took screenshot or save it anywhere. You can only be allowed to enter after verifying your registration number</p>
                    <h3>{registrationScreen.registrationNumber}</h3>
                    <button className="btn-a" onClick={handleClosingOfRegistrationScreen}>Done</button>
                </div>

                }
                
            </div>}
        </div>
    )
}